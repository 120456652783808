











































import {Route} from 'vue-router'
import {projectModule} from '@/store'
import {DEFAULT_PROPOSAL_FILTERS} from '@/helpers/proposals'
import {Component, Prop, Vue, Watch, PropSync} from 'vue-property-decorator'

import Modal from '@/components/widgets/Modal.vue'
import Submit from '@/components/project/Submit.vue'
import NoItems from '@/components/partials/NoItems.vue'
import ActionBar from '@/components/widgets/ActionBar.vue'
import ProposalsList from '@/components/project/ProposalsList.vue'
import DropdownFilter from '@/components/inputs/DropdownFilter.vue'
import ResponseModal from '@/components/widgets/ResponseModal.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import {ProcessResource} from '@/models/process/ProcessResource'
import {DocumentResource} from '@/models/documents/DocumentResource'
import {ProposalCollectionResource} from '@/models/proposals/ProposalCollectionResource'
import {ProposalCollectionLightResource} from '@/models/proposals/ProposalCollectionLightResource'

import {ProposalIndexRequest} from '@/requests/proposals/ProposalIndexRequest'

type ClonedRoute = Route

@Component({
  components: {
    Modal,
    Submit,
    NoItems,
    ActionBar,
    SmallLoader,
    ProposalsList,
    ResponseModal,
    DropdownFilter
  }
})
export default class ProjectsDetailDocumentProposals extends Vue {

  @PropSync('selectedProposals', {type: Array})
  private syncedSelectedProposals!: ProposalCollectionLightResource[]

  @Prop()
  private process!: ProcessResource

  private editing: boolean = false
  private isFetchingProposals: boolean = false

  // private showCreateModal: boolean = false
  // private showFilters: boolean = false
  // private proposalFilters: { [key: string]: { [key: string]: string } } = {}
  private proposalParams: ProposalIndexRequest = { ...DEFAULT_PROPOSAL_FILTERS }

  private proposals: ProposalCollectionResource[] = []
  private pagination: Pagination | null = null

  private coverageOptions: SelectItem[] = [
    {value: undefined, label: 'All'},
    {value: 'Covered', label: 'Covered'},
    {value: 'Uncovered', label: 'Uncovered'}
  ]

  @Watch('document.id')
  private onRouteChange(val: ClonedRoute): void {
    this.getData()
    this.$emit('selectedProposals', [])
  }

  private get selectedProposalIds(): number[] {
    return this.syncedSelectedProposals.map(({id}) => id)
  }

  private get canSelect(): boolean {
    return !this.editing
  }

  private get nextProcess(): ProcessResource | null {
    return projectModule.getProcessByOrder(this.process.order + 1)
  }

  private get document(): DetailResponse<DocumentResource> | null {
    return projectModule.detail ? projectModule.detail.document : null
  }

  private get openProposalId(): number | null {
    return this.$route.params.proposal_id
        ? parseInt(this.$route.params.proposal_id, 10)
        : null
  }

  private async openProposal(proposal: ProposalCollectionResource): Promise<void> {
    try {
      await this.$router.push({
        name: 'projects-detail-document-proposal-detail',
        params: {
          project_id: this.$route.params.project_id,
          document_id: this.$route.params.document_id,
          proposal_id: proposal.id.toString()
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  private onEditing(val: boolean): void {
    this.editing = val
  }

  private get AllCurrentProposalsSelected(): boolean {
    return this.proposals.every((proposal) => this.isSelected(proposal.id))
  }

  private isSelected(id: number): boolean {
    return this.syncedSelectedProposals.some((proposal) => proposal.id === id)
  }

  private toggleSelect(): void {
    const CURRENT_PROPOSAL_IDS = this.proposals.map(({id}) => id)
    // All proposals are already selected, So we deselect all CURRENT proposals
    if (this.AllCurrentProposalsSelected) {
      this.$emit('update:selectedProposals', this.syncedSelectedProposals.filter((proposal) => !CURRENT_PROPOSAL_IDS.includes(proposal.id)))
      // Select all current proposals
    } else {
      const REMAINING_PROPOSALS = this.proposals.filter(({id}) => !this.isSelected(id))
      const proposals = [...this.syncedSelectedProposals, ...REMAINING_PROPOSALS.map((p) => new ProposalCollectionLightResource(p))]
      this.$emit('update:selectedProposals', proposals)
    }
  }

  // Toggle select proposal
  private updateSelected(proposal: ProposalCollectionResource): void {

    const PROPOSAL_INDEX = this.syncedSelectedProposals.findIndex((p) => p.id === proposal.id)
    // Remove proposal
    if (PROPOSAL_INDEX > -1) {
      this.syncedSelectedProposals.splice(PROPOSAL_INDEX, 1)
      this.$emit('update:selectedProposals', this.syncedSelectedProposals)

      return
    }

    // Add proposal
    this.$emit('update:selectedProposals', [...this.syncedSelectedProposals, new ProposalCollectionLightResource(proposal)])
  }

  private deselectProposal(proposalId: number) {
    const PROPOSAL_INDEX = this.syncedSelectedProposals.findIndex((p) => p.id === proposalId)
    if (PROPOSAL_INDEX === -1) return
    this.syncedSelectedProposals.splice(PROPOSAL_INDEX, 1)
    this.$emit('update:selectedProposals', this.syncedSelectedProposals)
  }

  private async getData(): Promise<void> {
    if (!this.process && !this.document) return
    try {
      this.isFetchingProposals = true
      const data = await this.process.getDocumentProposals(this.document?.data.id ?? 0, this.proposalParams)
      this.proposals = data.data
      this.pagination = data.pagination ?? null
    } finally {
      this.isFetchingProposals = false
    }
  }

  private async created(): Promise<void> {
    // get init statuses
    this.proposalParams.status = this.$route.query.status as string[] || []
    this.proposalParams.page = this.$route.query.page || '1'
    await this.getData()
  }

  private setCoverage(coverage: any): void {
    this.proposalParams.coverage = coverage
    this.getData()
  }

  private async goToPage(page: string) {
    this.proposalParams.page = page
    await this.getData()
    this.scrollTop()
  }

  private scrollTop(): void {
    const proposalContainer = this.$refs.proposalList as Vue
    if (!proposalContainer) return
    proposalContainer.$el.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

}
